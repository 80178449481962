.no_secondary_nav {
    margin-top: -12rem !important;
    padding-bottom: 0 !important;
    .PageContent {
        padding: 0;
        border-radius: unset;
        border-left: 1px solid #E8E8E9;
    }
}

.PageContent {
    background-color: var(--white);
    padding: 1rem 1.5rem;
    border-radius: 3px;

    &.FullScreen {
        padding: 0;
        height: 100%;
    }
}

.PageTitle {
    color: var(--white);
    margin-bottom: 1rem;
    cursor: default;
    display: flex;

    .LoggedUserContainer {
        flex: 1;
        font-size: 14px;
    }

    h5 {
        font-size: 18px !important;
    }
}

.LoggedUser {
    text-decoration: underline;
}

.Borderless {
    margin-top: -1.75rem !important;
    .PageContent {
        background-color: transparent;
    }
}

.BorderlessWithMarginTop {
    .PageContent {
        background-color: transparent;
        padding: 0;
    }
}

.BorderlessFullscreen {
    margin-top: -2.5rem !important;
    padding: 0;

    .PageTitle {
        padding: 0 1.5rem;
    }

    .PageContent {
        border-radius: 0;
    }
}
