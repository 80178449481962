@import "/src/styles/core";

.Container {
    background-color: #E5E5E5;

    grid-area: chat-preview;

    height: calc(100vh - 149px);
    // height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    padding: 0 1.75rem;
    position: relative;

    .replay_wrapper {
        margin-top: 10px;
        padding: 5px 10px;
        background-color: #cacaca;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .replay {
            p {
                margin: 0;
                cursor: default;
            }
        }
        .close_btn {
            cursor: pointer;
            display: flex;
        }
    }

    .Messages {
        flex: 1;
        overflow-y: auto;
		padding-right: 1rem;

        @media screen and (max-width: 767px) {
            min-height: 250px;
        }

        @media screen and (max-height: 640px) {
            min-height: 150px;
        }

        &.no_messages {
            align-items: center;
            justify-content: center;
            display: flex;
            .no_messages {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .copied_success {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: calc(35% - 40px);
            left: calc(50% - 40px);
            right: calc(50% - 40px);
            bottom: calc(65% - 40px);
            width: 80px;
            height: 80px;
            background-color: rgba(0, 13, 36, 0.5);
            color: #fff;
            border-radius: 10px;
            font-size: 14px;
        }
    }
}

@include media-breakpoint-up(md) {
    .Container {
        height: calc(100vh);
        // height: calc(100vh - 100px);
    }
}
