@import "/src/styles/core";

.RegisterContainer {
    background-color: var(--white);
    padding: 1rem;
    // margin-top: 2rem;
}

@include media-breakpoint-up(md) {
    .RegisterContainer {
        padding: 2rem 6rem;
    }
}

@include media-breakpoint-up(lg) {
    .RegisterContainer {
        grid-auto-columns: 70% 30%;
    }
}
