.UserInfoContainer {
    padding: 1rem;
    text-align: center;

    .UserAvatarLogo {
        width: 108px;
        height: 108px;
        border-radius: 50%;
        margin: auto;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .Anon {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: var(--accent);
            position: relative;
            display: flex;
            overflow: hidden;

            .LockShade {
                margin-top: 1.5rem;
                opacity: 0.3;
                transform: scale(1.5);
            }

            .Lock {
                position: absolute;
                object-fit: scale-down;
            }
        }
    }

    .UserName {
        margin-top: 1rem;
        font-weight: 700 !important;

        h5 {
            font-size: 18px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                color: #323232;
                text-decoration: none;
                margin-right: 8px;
            }
        }
    }
}
