.DropdownIndicator {
    margin-right: 0.5rem;
}

label {
    color: var(--primary-scale-90);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 14px;
    padding-bottom: 0.5rem;

    .RequiredIndicator {
        color: var(--accent);
        font-weight: bold;
    }
}

.Container {
    &:not(.Marginless) {
        margin-bottom: 1rem;
    }
}

.ErrorInput {
    :global(.custom-select__control) {
        border: 1px solid var(--error) !important;
        background-color: #ff000017 !important;
    }

    .ErrorText {
        font-size: 0.75rem;
        color: var(--error) !important;
    }
}
