.more_actions_popover {
    padding: 15px;
    border-radius: 15px !important;
    border-color: #E0E0E0;
    & > div {
        &:first-of-type {
            top: 5px !important;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            border-right:10px solid #FFFFFF;
            &::before, &::after {
                border: none
            }
        }
    }
    &.message_send {
        & > div {
            &:first-of-type {
                border-right: transparent;
                border-left:10px solid #FFFFFF;
            }
        }
    }
    .more_actions_wrapper {
        .action {
            font-weight: 400;
            font-size: 16px;
            color: #606060;
            cursor: pointer;
            img {
                margin-right: 10px;
            }
            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }
    }
}

.Container {
    padding: 0.5rem 0;
    width: 80%;

    .Header {
        display: flex;
        align-items: center;

        .Avatar {
            width: 24px;
            height: 24px;
            .chat_user_avatar {
                font-size: 10px;
            }
        }

        .Time {
            padding-left: 0.5rem;
            font-size: 14px;
            color: #8f9cb3;
        }
    }

    .ContentContainer {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .Content {
            color: var(--primary-scale-90);
            line-height: 23px;
            padding: 11px 17px;
            // max-width: 85%;
            word-break: break-all;

            p {
                margin-bottom: 0 !important;
            }

            .replay_wrapper {
                .replay {
                    padding: 5px 10px;
                    background-color: #005DFF;
                    border-radius: 4px;
                    margin-bottom: 2px;
                }
            }
        }
    }
}

.Container {
    display: flex;

    &.Sent {
        flex-direction: column;
        align-items: end;
        margin-left: 20%;

        .Header {
            .Avatar {
                display: none;
            }
        }

        .ContentContainer {
            flex-direction: row-reverse;

            .Content {
                background-color: var(--accent);
                color: var(--white);
                border-radius: 4px;
                // max-width: 85%;
                word-break: break-all;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.Container {
    display: flex;

    &.Received {
        flex-direction: column;

        .ContentContainer {
            .Content {
                background-color: var(--white);
                color: var(--primary-scale-90);
                border-radius: 4px;
                // max-width: 85%;
                word-break: break-all;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.FileContainer {
	background-color: #00389B;
	border: 1px solid #005DFF;
	border-radius: 0.25rem;
	width: 120px;
	height: 90px;
	padding: 0.75rem;
	display: flex;
    flex-direction: column;
	align-items: center;
	position: relative;
	cursor: pointer;

	.ChatDocument {
		width: 42px;
		height: 42px;
	}

	.DownloadWhite {
		position: absolute;
		right: 5px;
		top: 5px;
	}

	.FileName {
		font-size: 0.75rem;
		margin-top: 0.25rem;
		color: white;
		max-width: 100px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.FilesContent {
	display: flex;
    gap: 0.5rem;
}

.Separator {
	padding-bottom: 5px;
}