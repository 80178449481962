.header_wrapper {
    .ad_info_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #DFDFDF;
        padding: 8px 0;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            row-gap: 10px;
        }
        .ad_info {
            display: flex;
            align-items: center;
            .ad_number {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #808080;
                span {
                    color: #0044BB;
                }
            }
            .vertical_line {
                border-right: 1px solid #DFDFDF;
                height: 15px;
                width: 1px;
                margin: 0 10px;
            }
            .date {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #808080;
            }
        }
        .actions_wrapper {
            display: flex;
            @media screen and (max-width: 469px) {
                display: grid;
                grid-template-columns: 100%;
                row-gap: 10px;
                width: 100%;
            }
            .download_doc {
                background: #0044BB;
                border-radius: 2px;
                padding: 5px 16px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                cursor: pointer;
                @media screen and (max-width: 469px) {
                    text-align: center;
                }
                img {
                    margin-right: 8px;
                }
            }
            .ask_question {
                text-decoration: none;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #0044BB;
                background: transparent;
                border: 1px solid #0044BB;
                border-radius: 2px;
                padding: 5px 16px;
                margin-left: 15px;
                cursor: pointer;
                @media screen and (max-width: 469px) {
                    margin-left: 0;
                    text-align: center;
                }
            }
        }
    }
    .company_info_wrapper {
        display: flex;
        border-bottom: 2px solid #DFDFDF;
        @media screen and (max-width: 767px) {
            display: grid;
            grid-template-columns: 100%;
            padding-bottom: 10px;
        }
        .image_wrapper {
            width: 206px;
            max-width: 100%;
            height: 206px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 767px) {
                width: auto;
            }
            .image {
                width: 102px;
                max-width: 100%;
                height: 102px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 13px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .company {
                margin-top: 28px;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #323232;
                text-align: center;
            }
        }
        .info_wrapper {
            margin-left: 32px;
            margin-top: 21px;
            .label_style {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #808080;
                &:not(:first-of-type) {
                    margin-top: 5px;
                }
            }
            .company {
                margin-top: 5px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #404040;
            }
        }
    }
}