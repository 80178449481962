.ad_form {
    padding: 16px 25px 0;
    &:not(:last-of-type) {
        .action_btns_wrapper {
            .add_more {
                display: none;
            }
        }
    }
    .inputs_wrapper {
        display: grid;
        grid-template-columns: calc(100% - 275px) auto;
        column-gap: 20px;
        @media screen and (max-width: 767px) {
            grid-template-columns: 100%;
        }
    }
    .input_wrapper {
        &.has_error {
            input, select, textarea {
                border-color: #FF0000 !important;
                &:focus, &:active {
                    border-color: red !important;
                }
            }
        }
        label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #404040;
            padding-bottom: 5px;
        }
        input {
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 6px;
            padding: 8px;
            width: 100%;
            &:focus, &:active {
                border-color: #DFDFDF !important;
            }
        }
        textarea {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 6px;
            padding: 8px 8px 25px;
            resize: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #323232;
            &:focus, &:active {
                border-color: #DFDFDF !important;
            }
            &:focus-visible {
                outline: none;
            }
        }
        select {
            height: 42px;
            background-image: url("../../../../../../assets/icons/CaretDownAccent.svg");
            &:focus, &:active {
                border-color: #DFDFDF !important;
                box-shadow: none;
            }
            &:focus-visible {
                outline: none;
            }
        }
        .input_container {
            position: relative;
            & > .count_characters {
                position: absolute;
                right: 22px;
                bottom: 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #828282;
                background-color: #FFFFFF;
                padding: 0 2px;
                span {
                    color: #0044BB;
                }
            }

            & > .icons_wrapper {
                position: absolute;
                right: 10px;
                top: 12px;
                display: flex;
                flex-direction: column;
                img {
                    &:first-of-type {
                        margin-bottom: 1px;
                    }
                    &:last-of-type {
                        margin-top: 1px;
                    }
                }
            }
        }
        .error {
            color: #FF0000;
            font-weight: 400;
            font-size: 12px;
            height: 18px;
            margin-top: 2px;
        }
    }
    .attach_images_wrapper {
        .images_wrapper {
            display: flex;
            flex-wrap: wrap;
            .image_wrapper{
                border: 2px solid #E3E7EE;
                border-radius: 10px;
                width: 216px;
                height: 216px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-bottom: 8px;
                @media screen and (max-width: 500px) {
                    width: 100%;
                    &:not(:last-of-type) {
                        margin-right: 0px !important;
                    }
                }
                &:not(:last-of-type) {
                    margin-right: 8px;
                }
                img {
                    max-width: 214px;
                    max-height: 214px;
                    border-radius: 10px;
                }
                .icon_wrapper {
                    position: absolute;
                    bottom: 12px;
                    cursor: pointer;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(227, 231, 238, 0.7);
                    border-radius: 100%;
                    .icon {
                        width: 30px;
                        height: 30px;
                        mask-image: url("./../../../../../../assets/icons/TrashIcon.svg");
                        mask-size: contain;
                        mask-repeat: no-repeat;
                        background: #25262D;
                    }
                }
            }
        }
        .attach_btn_wrapper {
            margin-top: 12px;
            label {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #323232;
            }
            .attach_button {
                display: flex;
                align-items: center;
                background: #0044BB;
                border-radius: 6px;
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #FBFBFB;
                padding: 8px 38px;
                .icon {
                    -webkit-mask-image: url("../../../../../../assets/icons/Image.svg");
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: contain;
                    mask-image: url("../../../../../../assets/icons/Image.svg");
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    background: #FFFFFF;
                    margin-right: 12px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .horizontal_line {
        margin-top: 24px;
        border-bottom: 1px solid #DFDFDF;
    }
    .action_btns_wrapper {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 500px) {
            flex-direction: column;
        }
        .remove {
            background: #FF0000;
            border-radius: 6px;
            border: none;
            padding: 12px 49px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #FBFBFB;
        }
        .add_more {
            display: flex;
            align-items: center;
            margin-left: 18px;
            border: 1px solid #0044BB;
            border-radius: 6px;
            background: transparent;
            padding: 12px 10px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #0044BB;
            @media screen and (max-width: 500px) {
                margin-left: 0;
                margin-top: 10px;
            }
            .icon {
                background-image: url("../../../../../../assets/icons/Plus.svg");
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 10px;
                width: 18px;
                height: 18px;
            }
        }
    }
}