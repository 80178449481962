@import "/src/styles/core";

.Footer {
    border-top: 1px solid var(--background-accent);
    padding-top: 1rem;

    button {
        width: 100% !important;
    }
}

@include media-breakpoint-up(lg) {
    .Footer {
        button {
            width: max-content !important;
        }
    }

    // .CancelButton {
    //     margin-right: -3rem !important;
    // }
}
