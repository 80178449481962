.CopyrightText {
    padding: 2rem;
    position: relative;
    text-align: center;
    background-color: var(--background-accent);

    a {
        text-decoration: none;
        color: #12b495;
    }
}
