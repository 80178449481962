@import "/src/styles/core";

.ChatContainer {
    display: grid;
    height: 100%;
    grid-template-areas:
        "chat-items"
        "chat-preview";
    grid-auto-columns: 100%;
    background: #E5E5E5;
    // grid-auto-rows: 135px auto;
}

// @include media-breakpoint-up(sm) {
//     .ChatContainer {
//         grid-auto-columns: 320px calc(100vw - 660px);
//     }
// }

@include media-breakpoint-up(md) {
    .ChatContainer {
        display: grid;
        grid-template-areas: "chat-items chat-preview";

        // grid-auto-columns: 320px auto;

        grid-auto-columns: 320px calc(100vw - 320px);
    }
}

@include media-breakpoint-up(xl) {
    .ChatContainer {
        grid-auto-columns: 320px auto;
    }
}
