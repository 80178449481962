.FloatInputContainer {
    height: 45px;
}

.FloatInput {
    background-color: var(--white);
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.05);
    padding: 0.3rem;

    .InputGroupText {
        width: 35px;
        border: 0;
        background-color: transparent;

        &.ToggleIcon {
            width: 24px !important;
            cursor: pointer;
            color: var(--accent);
            margin-right: 0.5rem;
        }
    }

    .FormControl {
        border: 0;
        background-color: transparent;

        &:focus {
            box-shadow: none;
        }
    }
}

.ErrorInput {
    &.FloatInput {
        box-shadow: inset 0px -3px 0px #ff000087 !important;
        background-color: #ff000017 !important;
    }

    .ErrorText {
        font-size: 0.75rem;
        color: var(--error) !important;
    }
}

.Errors {
    margin-top: -3px;

    .ErrorText {
        font-size: 0.75rem;
        color: var(--error) !important;
    }
}
