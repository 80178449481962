@import "/src/styles/core";

.Container {
	margin-bottom: 0rem;
	position: relative;

	.HeaderContainer {
		display: flex;

		.Label {
			flex: 1;
			font-size: 14px;
			color: var(--primary-scale-90);
			padding-bottom: 0.5rem;

			.RequiredIndicator {
				color: var(--accent);
				font-weight: bold;
			}
		}

		.CharCounter {
			font-size: 14px;

			.TotalCharacters {
				color: var(--accent);
			}

			.CharLabel {
				padding-left: 0.35rem;
			}
		}
	}
}

.DropArea {
	display: none;
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

	.DropContainerBorder {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: flex-end;
		justify-content: center;
		padding-bottom: 2rem;

		.Border {
			background-color: transparent;
			color: var(--accent);
			background-image: linear-gradient(to right, #ffffff 50%, white 50%);
			background-position: top;
			background-size: 10px 1px;
			background-repeat: repeat-x;
			border-color: #e0e0e0;
			border-width: 2px;
			border-style: dashed;
			border-radius: 1rem;
			width: 40%;
			height: 40%;
		}
	}

	.DropContainerImg {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: flex-end;
		justify-content: center;
		padding-bottom: 2rem;
		position: absolute;
		top: 0;
		overflow: hidden;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			transform: translate(45%, 45%);
		}
	}
}

.Opacity {
	opacity: 0.3;
}

.attach_button {
	padding: 5px;
    width: 30px;
	height: 30px;
	border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
	&:hover {
		background-color: #c0c0c073;
		box-shadow: none;
	}
	.icon {
		width: 16px;
		height: 14px;
		-webkit-mask-image: url("./../../../assets/icons/Attach_icon.svg");
		mask-image: url("./../../../assets/icons/Attach_icon.svg");
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background: #323232;
		mask-position: center;
		-webkit-mask-position: center;
	}
}

@include media-breakpoint-up(md) {
	.DropArea {
		display: block;
	}
}

@include media-breakpoint-up(lg) {
	.DropContainerImg {
		img {
			width: 60% !important;
			height: 80% !important;
			transform: translate(45%, 45%) !important;
		}
	}
}

@include media-breakpoint-up(xl) {
	.DropContainerImg {
		img {
			width: 40% !important;
			height: 80% !important;
			transform: translate(45%, 40%) !important;
		}
	}
}