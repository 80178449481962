:root {
    --white: #ffffff;
    --black: #000000;

    --accent: #0044bb;
    --error: rgb(212, 41, 41);
    --success: #6fcf97;
    --error-dimmed: #ff000017;

    --accent-hover: #cfdae673;
    --white-hover: #ffffff38;

    --background-accent: #ebedf4;

    --primary-scale-100: #000d24;
    --primary-scale-90: #323232;
    --primary-scale-80: #828282;
    --primary-scale-70: #fbfbfb;
    --primary-scale-60: #fbfbfb;

    --outline-scale-100: #e0e0e0;
    --outline-scale-90: #f2f2f2;
    --outline-scale-80: #efefef;
    --outline-scale-70: #fafafa;

    --global-loader: #ebedf494;

    --captcha: #b3b3b3;
}
