@import "/src/styles/core";

.Actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;

    .ActionButton {
        width: 44px;
        height: 44px;
        background-color: var(--white);
        border-radius: 50%;
        border: none;
        outline: none;
        position: relative;
        padding: 0;

        span {
            position: absolute;
        }

        // &:last-child {
        //     margin-right: 0.5rem;
        // }

        .Badge {
            width: 20px;
            height: 20px;
            font-size: 14px;
            line-height: normal;
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: var(--accent);
            color: var(--white);
            border: 2px solid var(--primary-scale-100);
            border-radius: 50%;
        }
    }
}

.LanguageFlag {
    position: absolute;
    top: 0px;
    left: -6px;
    width: 135%;
    height: 100%;
}

@include media-breakpoint-up(lg) {
    .Actions {
        flex: 1;
        text-align: right;
    }
}
