.offers_screen_wrapper {
    .table_wrapper {
        margin-top: 20px;
        margin-bottom: 10px;
        .table_container {
            margin-top: 15px;
            .offers_table {
                overflow-x: auto;
                display: block;
                width: 100%;
                white-space: nowrap;
                input[type=checkbox] {
                    width: 15px;
                    height: 15px;
                    position: relative;
                    top: 2px;
                }
                thead {
                    border: transparent;
                    tr {
                        th {
                            font-weight: 600;
                            font-size: 14px;
                            color: #252830;
                            background: #FAFAFA;
                            padding: 22px 8px;
                            border: none;
                            position: relative;
                            &:not(:last-of-type) {
                                &::after {
                                    content: "";
                                    border-right: 1px solid #EBEBEB;
                                    position: absolute;
                                    right: 0;
                                    top: 18px;
                                    height: 34px;
                                }
                            }
                            &:nth-child(2) {
                                min-width: 260px;
                                width: 260px;
                            }
                            &:nth-child(3) {
                                min-width: 208px;
                                width: 208px;
                            }
                            &:nth-child(4) {
                                min-width: 260px;
                                width: 260px;
                                max-width: 260px;
                            }
                            &:nth-child(5) {
                                min-width: 130px;
                                width: 130x;
                                max-width: 130px;
                            }
                            &:nth-child(6) {
                                min-width: 115px;
                                width: 115px;
                            }
                            .sort_wrapper {
                                display: grid;
                                grid-auto-rows: max-content max-content;
                                grid-row-gap: 2px;
                                margin-left: 5px;
                                .caret {
                                    display: inline-block;
                                    width: 0;
                                    height: 0;
                                    margin-left: 2px;
                                    vertical-align: middle;
                                    border-top: 4px dashed rgba(0, 0, 0, 0.25);
                                    border-top: 4px solid \9;
                                    border-right: 4px solid transparent;
                                    border-left: 4px solid transparent;
                                }
                                .caret_up {
                                    content: "";
                                    border-top: 0;
                                    border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
                                    border-bottom: 4px solid \9;
                                }
                            }
                        }
                    }
                }
                tbody {
                    border: none;
                    tr {
                        height: 60px;
                        td {
                            border: none;
                            border-bottom: 2px solid #FAFAFA;
                            vertical-align: middle;
                            font-weight: 400;
                            font-size: 14px;
                            color: #252830;
                            &:nth-child(2),
                            &:nth-child(4) {
                                min-width: 260px;
                                width: 260px;
                                max-width: 260px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
            .pagination_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 767px) {
                    flex-wrap: wrap;
                }
                & > span {
                    font-weight: 400;
                    font-size: 14px;
                    color: #252830;
                    @media screen and (max-width: 767px) {
                        min-width: 100%;
                    }
                }
                & > div {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        margin-top: 10px;
                        justify-content: space-between;
                        width: 100%;
                    }
                    & > ul {
                        margin: 0 16px 0 0;
                        li {
                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                            a {
                                border: 1px solid #D9D9D9;
                                border-radius: 2px;
                                background: #FFFFFF;
                                width: 32px;
                                height: 32px;
                                color: #252830;
                                font-weight: 400;
                                font-size: 14px;
                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    & > span {
                        button {
                            background: #FFFFFF;
                            border: 1px solid #D9D9D9;
                            border-radius: 2px;
                            height: 32px;
                            padding: 4px 12px;
                            color: #252830;
                            font-weight: 400;
                            font-size: 14px;
                            display: flex;
                            &:focus {
                                box-shadow: none;
                            }
                            &::after {
                                content: "";
                                position: relative;
                                right: 0px;
                                bottom: 4px;
                                border: none;
                                width: 10px;
                                height: 30px;
                                background-image: url("./../../../assets//icons/ArrowDown.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: 10px;
                            }
                        }
                        & > ul {
                            right: 0;
                            bottom: 32px;
                            min-width: 50px;
                            li {
                                a {
                                    text-decoration: none;
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #252830;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // status colors
    .status {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 8px;
        }
        &.declined {
            color: #FF0000;
            span {
                background-color: #FF0000;
            }
        }
        &.accepted {
            color: #00D015;
            span {
                background-color: #00D015;
            }
        }
        &.auction {
            color: #0044BB;
            span {
                background-color: #0044BB;
            }
        }
        &.in_progress {
            color: #FAA526;
            span {
                background-color: #FAA526;
            }
        }
        &.finished {
            color: #01B1E9;
            span {
                background-color: #01B1E9;
            }
        }
        &.canceled {
            color: #808080;
            span {
                background-color: #808080;
            }
        }
    }
}