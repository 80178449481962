.Container {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;

    .Month {
        flex: 1;

        .MonthContainer {
            width: 95%;
            margin-left: 5%;
        }
    }

    .Year {
        flex: 1;

        .YearContainer {
            width: 95%;
        }
    }
}
