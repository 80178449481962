.Container {
    display: flex;
    align-items: center;
    background-color: #323232;
    .Timer {
        .Value {
            background-color: var(--primary-scale-90);
            color: white;
            text-align: center;
            width: 32px;
            height: 23px;
            font-size: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 3px;
        }
        .Label {
            font-size: 7px;
            text-align: center;
            color: white;
        }
    }
}

.SeparatedContainer {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    background-color: var(--white);
    justify-content: center;
    .Timer {
        .Value {
            background-color: var(--primary-scale-90);
            color: white;
            text-align: center;
            width: 32px;
            height: 40px;
            font-size: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 3px;
            font-family: "Fira Code", monospace;
        }
        .Label {
            font-size: 7px;
            text-align: center;
            color: var(--primary-scale-90);
        }
    }
}

.ContainerLight {
    display: flex;
    .Timer {
        .Value {
            color: var(--primary-scale-100);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
        }
        .Label {
            font-size: 7px;
            text-align: center;
        }
    }
}

.container_auction {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    .timer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .value {
            width: 32px;
            height: 23px;
            font-size: 36px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .label {
            font-size: 14px;
            font-weight: 500;
            text-align: center;
        }
    }
    .colon {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        top: -13px;
    }
}