@import "/src/styles/core";

.StepTitle {
    border-bottom: 1px solid var(--background-accent);
}

.StepFormControls {
    input {
        border: 1px solid var(--outline-scale-80);

        &:active,
        &:focus {
            box-shadow: none;
            border: 1px solid var(--outline-scale-80);
        }
    }
}

.PhoneInput {
    display: block;
    margin-bottom: 1rem;
}

.PhoneInput > input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.PhoneInputError {
    display: block;
    margin-bottom: 0;
}

.PhoneInputError > input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-clip: padding-box;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 1px solid var(--error) !important;
    background-color: #ff000017 !important;
}

.PhoneErrorInput {
    border: 1px solid var(--error) !important;
    background-color: #ff000017 !important;
}

.PhoneInputErrorText {
    font-size: 0.75rem;
    color: var(--error) !important;
    margin-bottom: 1rem;
}

.RequiredIndicator {
    color: var(--accent);
    font-weight: bold;
}

.StepSection2 {
    border-bottom: 1px solid var(--background-accent);
}

.FormGrid {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr;
    grid-template-areas:
        "sectionOne"
        "sectionTwo"
        "sectionThree";
}

.Section1 {
    grid-area: sectionOne;
}

.Section2 {
    grid-area: sectionTwo;
}

.Section3 {
    grid-area: sectionThree;
    max-width: 200px;

    .File {
        min-height: 111px;
    }
}

.CompanyIndustryError {
    font-size: 0.75rem;
    color: var(--error) !important;
}

.MarginSeparator {
    margin-bottom: 0.5rem;
}

.ReCaptcha {
    margin-top: 1rem;

    .Label {
        font-size: 14px;

        &.Error {
            color: var(--error);
        }

        span {
            color: var(--accent);
        }
    }

    .CodeInput {
        display: flex;
        align-items: center;
        border: 1px solid var(--outline-scale-80);
        border-radius: 0.25rem;
        padding: 0.375rem 0.5rem;

        input {
            border: none;
            outline: none;
            width: 100px;
            color: var(--accent);
        }

        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: var(--accent);
            flex: 1;
            justify-content: flex-end;
            font-weight: 500;
            font-size: 14px;
            text-align: right;
        }
    }

    .GeneratedCode {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.375rem 0rem;

        img {
            cursor: pointer;
        }

        .Container {
            position: relative;

            img {
                position: absolute;
                width: 100%;
                z-index: 0;
            }

            .Skew {
                transform: skew(8deg, 6deg);
            }
        }
    }
}

#captcha {
    background-image: url("./../../../../assets/worn-dots.png");
}

.Logo {
    min-height: 128px;
}

@include media-breakpoint-up(md) {
    .FormGrid {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "sectionOne sectionTwo"
            "sectionThree .";
    }
}

@include media-breakpoint-up(lg) {
    .FormGrid {
        grid-template-columns: 1fr 1fr auto;
        grid-template-areas: "sectionOne sectionTwo sectionThree";
    }
}
