.SecondaryNavbar {
    display: flex;
    align-items: center;
    height: 220px;
    background-image: linear-gradient(
            90deg,
            #003797 0%,
            rgba(0, 68, 187, 0.34) 100%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
        url("../../../../assets/SecondaryNavBarBackground.svg");
    background-size: cover;
    background-position-y: center;

    // img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }
}
