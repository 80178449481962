.profile_page_wrapper {
    padding-right: 82px;
    .page_title {
        font-weight: 700;
        font-size: 18px;
        color: #252830;
    }
    .tabs_wrapper {
        margin-top: 40px;
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        .title {
            font-weight: 700;
            font-size: 18px;
            color: #252830;
        }
        nav {
            margin-top: 0;
            ul {
                border-bottom: none !important;
                justify-content: flex-end;
                li {
                    padding-top: 0;
                    padding-bottom: 0;
                    font-weight: 400;
                    font-size: 18px;
                    color: #323232;
                    &[class*="Tab_Active"] {
                        font-weight: 700;
                        color: #0044BB;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    .tabs_content_wrapper {
        padding-bottom: 21px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        .image_container {
            margin-top: 10px;
            .image_wrapper {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100px;
                    max-height: 100px;
                }
            }
            .upl_img_wrapper {
                display: flex;
                align-items: center;
                margin-top: 10px;
                .icon {
                    width: 20px;
                    height: 20px;
                    background-image: url("./../../assets/icons/Image_blue_icon.svg");
                    background-position: center;
                    background-size: auto;
                }
                .text {
                    margin-left: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #0044BB;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }
        .tab_one {
            display: grid;
            grid-template-columns: minmax(auto, 400px) minmax(auto, 400px);
            column-gap: 32px;
            margin-top: 23px;
            .col_one, .col_two {
                .input_wrapper {
                    &:not(:first-child) {
                        margin-top: 5px;
                    }
                    &.has_error {
                        input {
                            border-color: #FF0000 !important;
                            &:focus, &:active {
                                border-color: red !important;
                            }
                        }
                    }
                    label {
                        padding-bottom: 4px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #323232;
                        span {
                            color: #0044BB;
                        }
                    }
                    input {
                        background: #FFFFFF;
                        border: 1px solid #F2F2F2;
                        border-radius: 6px;
                        width: 100%;
                        height: 40px;
                        padding: 10px 8px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #323232;
                        &:focus, &:active {
                            border-color: #F2F2F2 !important;
                        }
                        &:disabled {
                            border-color: #F2F2F2 !important;
                            background: #FBFBFB !important;
                        }
                    }
                    .error {
                        color: #FF0000;
                        font-weight: 400;
                        font-size: 12px;
                        height: 18px;
                        margin-top: 2px;
                    }
                }
                .inputs_container {
                    display: grid;
                    grid-template-columns: calc(50% - 3.5px) calc(50% - 3.5px);
                    column-gap: 7px;
                    .input_wrapper {
                        margin-top: 5px;
                    }
                }
            }
        }
        .company_info_wrapper {
            label {
                padding-bottom: 4px;
                font-weight: 400;
                font-size: 14px;
                color: #323232; 
            }
            textarea {
                background: #FFFFFF;
                border: 1px solid #E0E0E0;
                border-radius: 6px;
                font-weight: 400;
                font-size: 16px;
                color: #323232;
                width: 100%;
                max-width: 832px;
                padding: 10px 8px;
                resize: none;
                outline: none;
            }
        }
        .current_state_wrapper {
            margin-top: 15px;
            label {
                font-weight: 500;
                font-size: 16px;
                color: #323232;
            }
            .file_wrapper {
                display: flex;
                flex-direction: column;
                width: 235px;
                max-width: 100%;
                height: 161px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                &.no_file {
                    justify-content: flex-end;
                    height: auto;
                    .file_text {
                        align-items: center;
                        padding: 0;
                        .file_info {
                            .name {
                                text-decoration-line: none !important;
                                white-space: normal;
                                overflow: unset;
                                text-overflow: unset;
                                max-width: none;
                            }
                        }
                    }
                }
                .file_image {
                    height: 115px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
                .file_text {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .file_info {
                        padding: 5px 14px;
                        .name {
                            font-weight: 500;
                            font-size: 14px;
                            text-decoration-line: underline;
                            color: #323232;
                            line-height: normal;
                            cursor: pointer;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 120px;
                        }
                        .size {
                            font-size: 14px;
                            color: #828282;
                            line-height: normal;
                        }
                    }
                    .actions {
                        display: flex;
                        align-items: center;
                        padding: 5px 14px;
                        .attach {
                            width: 24px;
                            height: 24px;
                            -webkit-mask-image: url("../../assets/icons/Attach_icon.svg");
                            -webkit-mask-repeat: no-repeat;
                            -webkit-mask-position: center;
                            mask-image: url("../../assets/icons/Attach_icon.svg");
                            mask-repeat: no-repeat;
                            mask-position: center;
                            background: #0044BB;
                            cursor: pointer;
                        }
                        .remove {
                            cursor: pointer;
                            margin-left: 10px;
                            width: 24px;
                            height: 24px;
                            -webkit-mask-image: url("../../assets/icons/TrashIcon.svg");
                            -webkit-mask-repeat: no-repeat;
                            mask-image: url("../../assets/icons/TrashIcon.svg");
                            mask-repeat: no-repeat;
                            background: #0044BB;
                        }
                    }
                }
            }
        }
        .more_ads_info_wrapper {
            margin-top: 15px;
            label {
                font-weight: 500;
                font-size: 16px;
                color: #323232;
                padding: 0;
            }
            .checkboxes_wrapper {
                margin-top: 5px;
                .checkbox_wrapper {
                    display: flex;
                    align-items: center;
                    max-width: 832px;
                    width: fit-content;
                    &:not(:last-of-type) {
                        margin-bottom: 5px;
                    }
                    position: relative;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    input {
                        opacity: 0;
                        cursor: pointer;
                        height: 16px;
                        width: 16px;
                        &:checked ~ .checkmark {
                            background-color: #9F9F9F;
                            &:after {
                                display: block;
                            }
                        }
                    }
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background-color: #eee;
                        border-radius: 2px;
                        border: 1px solid #D9D9D9;
                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                            left: 4.5px;
                            top: 1.5px;
                            width: 4px;
                            height: 9px;
                            border: solid #FFFFFF;
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }
                    .text {
                        padding: 0;
                        margin-left: 8px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #323232;
                    }
                }
            }
        }
        .tab_two {
            display: grid;
            grid-template-columns: minmax(auto, 400px) minmax(auto, 400px);
            column-gap: 32px;
            margin-top: 57px;
            padding-bottom: 22px;
            .col_one, .col_two {
                .input_wrapper {
                    &:not(:first-child) {
                        margin-top: 5px;
                    }
                    &.has_error {
                        input {
                            border-color: #FF0000 !important;
                            &:focus, &:active {
                                border-color: red !important;
                            }
                        }
                    }
                    label {
                        padding-bottom: 4px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #323232;
                        span {
                            color: #0044BB;
                        }
                    }
                    input {
                        background: #FFFFFF;
                        border: 1px solid #F2F2F2;
                        border-radius: 6px;
                        width: 100%;
                        height: 40px;
                        padding: 10px 8px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #323232;
                        &:focus, &:active {
                            border-color: #F2F2F2 !important;
                        }
                        &:disabled {
                            border-color: #F2F2F2 !important;
                            background: #FBFBFB !important;
                        }
                    }
                    .error {
                        color: #FF0000;
                        font-weight: 400;
                        font-size: 12px;
                        height: 18px;
                        margin-top: 2px;
                    }
                }
            }
        }
    }
    .footer_wrapper {
        margin-top: 22px;
        padding-bottom: 40px;
        .info_wrapper {
            .title {
                font-weight: 500;
                font-size: 16px;
                color: #323232;
            }
            & > div:nth-child(2) {
                margin: 8px 0 0;
            }
        }
        .buttons_wrapper {
            margin-top: 45px;
            display: flex;
            justify-content: flex-end;
            .cancel {
                font-weight: 500;
                font-size: 16px;
                color: #0044BB;
                border: none;
                background: transparent;
                border-radius: 6px;
                padding: 12px 64px
            }
            .save {
                background: #0044BB;
                border: #0044BB;
                border-radius: 6px;
                font-weight: 500;
                font-size: 16px;
                color: #FBFBFB;
                padding: 12px 64px
            }
        }
    }
}