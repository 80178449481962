@import "/src/styles/core";

.Login {
    background-color: var(--primary-scale-70);
    height: 100vh;
    overflow: hidden;

    .LoginForm {
        width: 400px;
    }
}

.LoginContainer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 0% 100%;
    height: 100%;
}

@include media-breakpoint-up(md) {
    .LoginContainer {
        grid-auto-columns: 60% 40%;
    }
}

@include media-breakpoint-up(lg) {
    .LoginContainer {
        grid-auto-columns: 70% 30%;
    }
}
