.LoginTextContainer {
    color: var(--primary-scale-70);
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100vh;

    .LoginText {
        height: 90vh;
        display: flex;
        align-items: center;
    }

    .CopyrightText {
        padding: 2rem;

        a {
            text-decoration: none;
            color: #12b495;
        }
    }
}
