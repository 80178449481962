.header_content_container {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 30px;
    @media screen and (max-width: 767px) {
        display: block;
    }
    .back_btn {
        background: #FFFFFF;
        border-radius: 16px;
        height: 30px;
        margin-top: 17px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: #0044BB;
        border: none;
        padding: 4px 10px;
        text-underline-offset: 3px;
    };
    .coundown_container {
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 33px 66px -12px rgba(121, 170, 255, 0.22);
        border-radius: 8px;
        display: flex;
        align-items: center;
        .icon_wrapper {
            width: 48px;
            height: 78px;
            background: #01B1E9;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
        .coundown_wrapper {
            .label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #323232;
            }
            .countdown {
                margin-top: 4px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.auction_bidding_screen_wrapper {
    .header_wrapper {
        display: grid;
        grid-template-columns: auto max-content;
        border-bottom: 1px solid #DFDFDF;
        padding-bottom: 8px;
        @media screen and (max-width: 500px) {
            grid-template-columns: 100%;
        }
        .page_title {
            font-weight: 700;
            font-size: 18px;
            color: #252830;
        }
        .auction_number {
            font-weight: 600;
            font-size: 18px;
            color: #808080;
            span {
                color: #0044BB;
            }
        }
    }
    & > .auction_info_wrapper {
        display: grid;
        grid-template-columns: auto 380px;
        column-gap: 25px;
        @media screen and (max-width: 992px) {
            grid-template-columns: 100% !important;
        }
        .auction_info {
            .details {
                margin-top: 10px;
                padding-bottom: 10px;
                &:not(:last-child) {
                    border-bottom: 1.31559px solid #DFDFDF;
                }
                .title_wrapper {
                    display: grid;
                    grid-template-columns: 75px auto;
                    column-gap: 20px;
                    @media screen and (max-width: 500px) {
                        grid-template-columns: 100%;
                    }
                    .image_wrapper {
                        width: 75px;
                        height: 75px;
                        border-radius: 4px;
                        border: 0.6px solid #E3E7EE;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 75px;
                            max-height: 75px;
                        }
                    }
                    .title_container {
                        .title {
                            font-weight: 600;
                            font-size: 18px;
                            color: #404040;
                        }
                        .category {
                            font-weight: 400;
                            font-size: 16px;
                            color: #606060;
                            margin-top: 5px;
                        }
                    }
                }
                .description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #323232;
                    margin-top: 10px;
                }
                .company_quantity {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 8px;
                    .company_wrapper {
                        font-style: normal;
                        .label {
                            font-weight: 600;
                            font-size: 16px;
                            color: #404040;
                        }
                        .name {
                            .company_link {
                                font-weight: 400;
                                font-size: 18px;
                                color: #0044BB;
                                text-decoration: none;
                            }
                        }
                    }
                    .quantity_wrapper {
                        font-style: normal;
                        .label {
                            font-weight: 600;
                            font-size: 16px;
                            color: #404040;
                        }
                        .quantity {
                            font-weight: 400;
                            font-size: 18px;
                            color: #606060;
                        }
                    }
                }
            }
        }
        .bidding_wrapper {
            margin-top: 10px;
            @media screen and (max-width: 992px) {
                margin-top: 25px;
            }
            .bidding_container {
                border: 2px solid #0044BB;
                border-radius: 8px;
                padding: 16px;
                .last_offer_label {
                    font-weight: 500;
                    font-size: 16px;
                    color: #323232;
                }
                .last_offer {
                    margin-top: 4px;
                    height: 32px;
                    width: 100%;
                    background: #C9E0FF;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    img {
                        margin-right: 8px;
                        margin-left: 4px;
                    }
                    span {
                        font-weight: 400;
                        font-size: 22px;
                        color: #0044BB;
                    }
                }
                .new_offer_label {
                    margin-top: 12px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #323232;
                }
                .offer_input_wrapper {
                    margin-top: 4px;
                    position: relative;
                    display: flex;
                    .icon_wrapper {
                        position: absolute;
                        top: 8px;
                        left: 17px;
                        width: 24px;
                        height: 24px;
                        background: #F2F2F2;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 7px;
                        & > img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .offer_input {
                        background-color: #FFFFFF;
                        border: 1px solid #DFDFDF;
                        border-radius: 6px 0 0 6px;
                        width: 100%;
                        padding: 8px 5px 8px 50px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #0044BB;
                        border-right: none;
                        &:focus, &:active {
                            border-color: #E0E0E0 !important;
                        }
                    }
                    .currency {
                        height: 42px;
                        display: flex;
                        align-items: center;
                        padding: 10px 16px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #0044BB;
                        background: #FFFFFF;
                        border: 1px solid #E0E0E0;
                        border-left: none;
                        border-radius: 0 6px 6px 0;
                        position: relative;
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 4px;
                            height: 30px;
                            width: 1px;
                            border-left: 1px solid #E0E0E0;
                          }
                    }
                }
                .send_offer {
                    background: #0044BB;
                    border-radius: 4px;
                    border: none;
                    width: 100%;
                    margin-top: 17px;
                    padding: 10px 0;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FBFBFB;
                    &.success {
                        background: #6FCF97; 
                    }
                    &:disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
    .rang_list_wrapper {
        margin-top: 24px;
        @media screen and (max-width: 992px) {
            margin-top: 25px;
        }
        .rang_list_title {
            font-weight: 400;
            font-size: 20px;
            color: #323232;
        }
        .rang_list_table {
            margin-top: 16px;
            overflow-x: auto;
            display: block;
            width: 100%;
            white-space: nowrap;
            .bidding_table {
                thead {
                    tr {
                        th {
                            height: 50px;
                            vertical-align: middle;
                            background: #0044BB;
                            font-weight: 400;
                            font-size: 16px;
                            color: #FFFFFF;
                            border-top: 1px solid #0044BB;
                            border-left: 3px solid #FFFFFF;
                            border-right: 3px solid #FFFFFF;
                            border-bottom: 3px solid #FFFFFF;
                            &:first-child {
                                min-width: 250px;
                                border-left: none;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                            &:nth-child(2) {
                                border-right: 3px solid #0044BB;
                                text-align: right;
                                padding-left: 23px;
                                padding-right: 23px;
                            }
                            &:last-child {
                                min-width: 250px;
                                border-right: none;
                                text-align: right;
                                padding-left: 23px;
                                padding-right: 23px;
                            }
                        }
                    }
                }
                tbody {
                    border-top: none;
                    tr {
                        &:first-of-type {
                            td {
                                background: #EEF1F5;
                                &:last-child {
                                    font-weight: 700;
                                    font-size: 24px;
                                    color: #606060;
                                }
                            }
                        }
                        td {
                            height: 70px;
                            vertical-align: middle;
                            border: 3px solid transparent;
                            box-shadow: #E0E0E0 inset 0 0 0 1px;
                            color: #606060;
                            &:first-child {
                                border-left: none;
                                color: #0044BB;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                            &:nth-child(2) {
                                text-align: right;
                                padding-left: 23px;
                                padding-right: 23px;
                            }
                            &:last-child {
                                border-right: none;
                                text-align: right;
                                padding-left: 23px;
                                padding-right: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
    .table_wrapper {
        margin-top: 20px;
        margin-bottom: 10px;
        .table_container {
            margin-top: 15px;
            .offers_table {
                overflow-x: auto;
                display: block;
                width: 100%;
                white-space: nowrap;
                thead {
                    border: transparent;
                    tr {
                        th {
                            font-weight: 600;
                            font-size: 14px;
                            color: #252830;
                            background: #FAFAFA;
                            padding: 22px 8px;
                            border: none;
                            position: relative;
                            height: 70px;
                            &:not(:last-of-type) {
                                &::after {
                                    content: "";
                                    border-right: 1px solid #EBEBEB;
                                    position: absolute;
                                    right: 0;
                                    top: 12px;
                                    height: 46px;
                                }
                            }
                            &:nth-child(1) {
                                min-width: 508px;
                                width: 508px;
                            }
                            &:nth-child(2) {
                                min-width: 160px;
                                width: 160px;
                            }
                            &:nth-child(3) {
                                min-width: 230px;
                                width: 230px;
                            }
                            .sort_wrapper {
                                display: grid;
                                grid-auto-rows: max-content max-content;
                                grid-row-gap: 2px;
                                margin-left: 5px;
                                .caret {
                                    display: inline-block;
                                    width: 0;
                                    height: 0;
                                    margin-left: 2px;
                                    vertical-align: middle;
                                    border-top: 4px dashed rgba(0, 0, 0, 0.25);
                                    border-top: 4px solid \9;
                                    border-right: 4px solid transparent;
                                    border-left: 4px solid transparent;
                                }
                                .caret_up {
                                    content: "";
                                    border-top: 0;
                                    border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
                                    border-bottom: 4px solid \9;
                                }
                            }
                        }
                    }
                }
                tbody {
                    border: none;
                    tr {
                        height: 60px;
                        td {
                            border: none;
                            border-bottom: 1px solid #DFDFDF;
                            vertical-align: middle;
                            font-weight: 400;
                            font-size: 14px;
                            color: #252830;
                            &:nth-child(1) {
                                min-width: 508px;
                                width: 508px;
                                max-width: 508px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                & > div {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    text-decoration-line: underline;
                                    color: #0044BB;
                                    cursor: pointer;
                                }
                            }
                            &:nth-child(2) {
                                min-width: 160px;
                                width: 160px;
                                max-width: 160px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            &:last-of-type {
                                button {
                                    border: none;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: center;
                                    color: #FFFFFF;
                                    padding: 9px 15px;
                                    border-radius: 4px;
                                    &:focus-visible {
                                        outline: none;
                                    }
                                    .icon {
                                        -webkit-mask-size: contain;
                                        mask-size: contain;
                                        -webkit-mask-repeat: no-repeat;
                                        mask-repeat: no-repeat;
                                        background: #FFFFFF;
                                        margin-right: 8px;
                                        vertical-align: middle;
                                        display: inline-block;
                                    }
                                }
                                .btn_reject {
                                    background: #FC3333;
                                    .icon {
                                        -webkit-mask-image: url("../../assets/icons/Close.svg");
                                        mask-image: url("../../assets/icons/Close.svg");
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                                .btn_accept {
                                    margin-left: 10px;
                                    background: #41CA00;
                                    .icon {
                                        -webkit-mask-image: url("../../assets/icons/Checkmark_White.svg");
                                        mask-image: url("../../assets/icons/Checkmark_White.svg");
                                        width: 16px;
                                        height: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .pagination_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 767px) {
                    flex-wrap: wrap;
                }
                & > span {
                    font-weight: 400;
                    font-size: 14px;
                    color: #252830;
                    @media screen and (max-width: 767px) {
                        min-width: 100%;
                    }
                }
                & > div {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        margin-top: 10px;
                        justify-content: space-between;
                        width: 100%;
                    }
                    & > ul {
                        margin: 0 16px 0 0;
                        li {
                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                            a {
                                border: 1px solid #D9D9D9;
                                border-radius: 2px;
                                background: #FFFFFF;
                                width: 32px;
                                height: 32px;
                                color: #252830;
                                font-weight: 400;
                                font-size: 14px;
                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                    & > span {
                        button {
                            background: #FFFFFF;
                            border: 1px solid #D9D9D9;
                            border-radius: 2px;
                            height: 32px;
                            padding: 4px 12px;
                            color: #252830;
                            font-weight: 400;
                            font-size: 14px;
                            display: flex;
                            &:focus {
                                box-shadow: none;
                            }
                            &::after {
                                content: "";
                                position: relative;
                                right: 0px;
                                bottom: 4px;
                                border: none;
                                width: 10px;
                                height: 30px;
                                background-image: url("./../../assets//icons/ArrowDown.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: 10px;
                            }
                        }
                        & > ul {
                            right: 0;
                            bottom: 32px;
                            min-width: 50px;
                            li {
                                a {
                                    text-decoration: none;
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #252830;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.accept_btn_style {
    background: #00D015 !important;
    border-radius: 4px !important;
    padding: 10px;
    .icon {
        -webkit-mask-image: url("../../assets/icons/Checkmark_White.svg");
        mask-image: url("../../assets/icons/Checkmark_White.svg");
        width: 16px;
        height: 10px;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        background: #FFFFFF;
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
    }
}

.refuse_btn_style {
    background: #FC3333 !important;
    border-radius: 4px !important;
    padding: 10px;
    .icon {
        -webkit-mask-image: url("../../assets/icons/Close.svg");
        mask-image: url("../../assets/icons/Close.svg");
        width: 10px;
        height: 10px;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        background: #FFFFFF;
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
    }
}

.accepted {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #00D015;
    display: flex;
    align-items: center;
    span {
        background: #00D015;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-right: 8px;
    }
}

.rejected {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FF0000;
    display: flex;
    align-items: center;
    span {
        background: #FF0000;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-right: 8px;
    }
}