.UploadedFileList {
    border: 1px solid var(--accent);
    padding: 0.35rem 1rem;
    border-radius: 0.35rem;
}

.UploadedFileSingle {
    height: auto;
}

.FileInput {
    padding: 0.5rem 0 0 0;
    max-width: 200px;
    display: flex;
    align-items: flex-start;
    cursor: default;

    .FileIcon {
        padding-right: 0.5rem;
    }

    .FileInfo {
        flex: 1;
        overflow: hidden;
    }

    .FileName {
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        max-width: 100%;
    }

    .FileSizeLimit {
        font-size: 14px;
        color: var(--primary-scale-80);
    }
}

.FileProgress {
    display: flex;
    align-items: center;
    align-self: center;

    .ProgressBar {
        flex: 1;
        height: 6px;
        border-radius: 3px;
        background-color: #0044bb94;
        position: relative;
        margin-top: 2px;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 0%;
            background-color: var(--accent);
            border-radius: 3px;
            animation-delay: 200ms;
            animation: 300ms fake-load forwards;
        }
    }

    .ClearButton {
        button {
            outline: none;
            border: none;
            background-color: transparent;
            // margin-left: 0.5rem;
        }
    }
}

.UploadedFilesMultiple {
    display: flex;
    align-items: center;
    gap: 1rem;

    border-bottom: 1px solid var(--accent);
    padding: 0.75rem 0.35rem;

    .ClearButton {
        button {
            outline: none;
            border: none;
            background-color: transparent;
            margin-left: 0.5rem;
        }
    }

    .FileInfo {
        flex: 1;

        .FileName {
            font-weight: 500;
            font-size: 1rem;
            text-decoration: underline;
            flex: 1;
            max-width: 100%;
        }

        .FileAdditionalInfo {
            font-size: 0.75rem;
        }
    }
}

.UploadedFileOneLine {
	&.Minimal {
		border: 1px solid #f5f7f6;
		padding: 0.5rem 1rem;
		border-radius: 1.5rem;
	}

    .FileInput {
        max-width: 100%;
        gap: 0.5rem;
        padding: 0;
    }

    .FileName {
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
    }

    .FileSizeLimit {
        align-self: center;
    }

    .FileProgress {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .ProgressBar {
            width: 160px !important;
            height: 6px;
            border-radius: 3px;
            background-color: #0044bb94;
            position: relative;
            margin-top: 2px;

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 0%;
                background-color: var(--accent);
                border-radius: 3px;
                animation-delay: 200ms;
                animation: 300ms fake-load forwards;
            }
        }

        .ClearButton {
            button {
                outline: none;
                border: none;
                background-color: transparent;
                // margin-left: 0.5rem;
            }
        }
    }
}

.MinimalButton {
	outline: none;
	border: none;
	background-color: transparent;
}

.chat_file_upload {
    .FileInput {
        max-width: none;
        gap: 5px;
        align-items: center;
    }
    .FileIcon {
        padding-right: 0;
    }
    .dot_icon {
        width: 4px;
        height: 4px;
        background-color: #212529;
        border-radius: 50%;
    }
    .FileInfo {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    .FileProgress {
        width: 100%;
        max-width: 163px;
        height: 25px;
        gap: 5px;
        .ProgressBar {
            margin: 0;
        }
    }
}

@media screen and (max-width: 544px) {
    .chat_file_upload {
        .FileInput {
            display: block;
        }
        .FileProgress {
            max-width: none;
        }
    }
}

@keyframes fake-load {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
