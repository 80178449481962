.body_style {
    padding: 34px 33px;
    .modal_title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #252830;
    }
    .content_container {
        margin-top: 25px;
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            color: #0044BB;
            margin-bottom: 25px;
        }
        .text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #323232;
            margin-bottom: 30px;
        }
    }
}
.close_btn {
    width: 170px;
    height: 44px;
    background: #0044BB;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #FBFBFB;
    margin-bottom: 24px;
    border: none;
    &:focus:active, &:focus {
        box-shadow: none;
    }
    &:focus-visible {
        outline: none;
    }
}