.ProductItems {
    .product_wrapper {
        margin-top: 10px;
        border-bottom: 1px solid #DFDFDF;
        display: grid;
        grid-template-columns: auto 175px;
        column-gap: 20px;
        @media screen and (max-width: 991px) {
            grid-template-columns: 100%;
        }
        .product_info_wrapper {
            .header_wrapper {
                display: flex;
                align-items: center;
                @media screen and (max-width: 439px) {
                    flex-direction: column;
                    row-gap: 10px;
                }
                .image_wrapper {
                    min-width: 75px;
                    width: 75px;
                    min-height: 75px;
                    height: 75px;
                    border: 0.5px solid #E3E7EE;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 70px;
                        max-height: 70px;
                        border-radius: 4px;
                    }
                }
                .title_wrapper {
                    margin-left: 20px;
                    @media screen and (max-width: 439px) {
                        margin-left: 0;
                    }
                    .title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        color: #404040;
                    }
                    .category {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: normal;
                        color: #606060;
                    }
                }
            }
            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #323232;
                margin: 10px 0 16px 0;
                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #0044BB;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
            .images_wrapper {
                display: flex;
                flex-wrap: wrap;
                column-gap: 5px;
                row-gap: 5px;
                padding: 0 0 16px 0;
                .image_wrapper {
                    border: 1px solid #E3E7EE;
                    border-radius: 5px;
                    width: 116px;
                    height: 116px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img {
                        max-width: 110px;
                        max-height: 110px;
                        border-radius: 5px;
                    }
                }
            }
        }
        .sidebar_wrapper {
            margin-bottom: 16px;
            @media screen and (max-width: 991px) {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;
            }
            @media screen and (max-width: 469px) {
                grid-template-columns: 100%;
                row-gap: 10px;
            }
            .quantity_wrapper {
                margin-top: 5px;
                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    color: #404040;
                }
                .quantity {
                    margin-top: 8px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #606060;
                }
            }
            .price_wrapper {
                margin-top: 25px;
                @media screen and (max-width: 991px) {
                    margin-top: 5px;
                }
                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    color: #404040;
                }
                .input_wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    input {
                        width: 130px;
                        margin-right: 10px;
                        background: #FFFFFF;
                        border: 1px solid #D3D3D3;
                        border-radius: 6px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        color: #323232;
                        padding: 4px 8px;
                        &:disabled {
                            background: #E3E3E3;
                        }
                        &:focus, &:active {
                            border-color: #D3D3D3 !important;
                        }
                        &:focus-visible {
                            outline: none;
                        }
                        @media screen and (max-width: 469px) {
                            width: auto;
                        }
                    }
                    .currency {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        color: #808080;
                    }
                }
            }
        }
    }
}