.table_wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    .buttons_wrapper {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-column-gap: 18px;
        padding: 0 4px 5px;
        border-bottom: 1px solid #E0E0E0;
        @media screen and (max-width: 767px) {
            overflow-x: auto;
            overflow-y: hidden;
        }
        .button {
            font-weight: 400;
            font-size: 16px;
            color: #323232;
            padding: 0 6px;
            cursor: pointer;
            position: relative;
            .badge {
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                background-color: #0044BB !important;
            }
            .text {
                font-weight: 400;
                font-size: 12px;
            }
            &.active {
                color: #0044BB;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    bottom: -7px;
                    width: 100%;
                    height: 3px;
                    border-bottom: 3px solid #0044BB;
                    border-radius: 20px;
                    z-index: 2;
                }
            }
        }
    }
    .table_container {
        margin-top: 15px;
        .offers_table {
            overflow-x: auto;
            display: block;
            width: 100%;
            white-space: nowrap;
            input[type=checkbox] {
                width: 15px;
                height: 15px;
                position: relative;
                top: 2px;
            }
            thead {
                border: transparent;
                tr {
                    th {
                        font-weight: 600;
                        font-size: 14px;
                        color: #252830;
                        background: #FAFAFA;
                        padding: 22px 8px;
                        border: none;
                        &:nth-child(2) {
                            min-width: 155px;
                            width: 155px;
                        }
                        &:nth-child(3) {
                            min-width: 200px;
                            width: 200px;
                        }
                        &:nth-child(4) {
                            min-width: 230px;
                            width: 230px;
                            max-width: 230px;
                        }
                        &:nth-child(5) {
                            min-width: 230px;
                            width: 230x;
                            max-width: 230px;
                        }
                        &:nth-child(6) {
                            min-width: 100px;
                            width: 100px;
                        }
                        &:nth-child(7) {
                            min-width: 108px;
                            width: 108px;
                        }
                        &:nth-child(8) {
                            min-width: 100px;
                            width: 100px;
                        }
                        .sort_wrapper {
                            display: grid;
                            grid-auto-rows: max-content max-content;
                            grid-row-gap: 2px;
                            margin-left: 5px;
                            .caret {
                                display: inline-block;
                                width: 0;
                                height: 0;
                                margin-left: 2px;
                                vertical-align: middle;
                                border-top: 4px dashed rgba(0, 0, 0, 0.25);
                                border-top: 4px solid \9;
                                border-right: 4px solid transparent;
                                border-left: 4px solid transparent;
                            }
                            .caret_up {
                                content: "";
                                border-top: 0;
                                border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
                                border-bottom: 4px solid \9;
                            }
                        }
                    }
                }
            }
            tbody {
                border: none;
                tr {
                    height: 60px;
                    td {
                        border: none;
                        border-bottom: 2px solid #FAFAFA;
                        vertical-align: middle;
                        font-weight: 400;
                        font-size: 14px;
                        color: #252830;
                        &:nth-child(3) {
                            a {
                                color: #252830;
                                text-decoration: none;
                            }

                        }
                        &:nth-child(4) {
                            min-width: 230px;
                            width: 230px;
                            max-width: 230px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &:nth-child(5) {
                            min-width: 230px;
                            width: 230px;
                            max-width: 230px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .pagination_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 767px) {
                flex-wrap: wrap;
            }
            & > span {
                font-weight: 400;
                font-size: 14px;
                color: #252830;
                @media screen and (max-width: 767px) {
                    min-width: 100%;
                }
            }
            & > div {
                display: flex;
                align-items: center;
                @media screen and (max-width: 767px) {
                    margin-top: 10px;
                    justify-content: space-between;
                    width: 100%;
                }
                & > ul {
                    margin: 0 16px 0 0;
                    li {
                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                        a {
                            border: 1px solid #D9D9D9;
                            border-radius: 2px;
                            background: #FFFFFF;
                            width: 32px;
                            height: 32px;
                            color: #252830;
                            font-weight: 400;
                            font-size: 14px;
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
                & > span {
                    button {
                        background: #FFFFFF;
                        border: 1px solid #D9D9D9;
                        border-radius: 2px;
                        height: 32px;
                        padding: 4px 12px;
                        color: #252830;
                        font-weight: 400;
                        font-size: 14px;
                        display: flex;
                        &:focus {
                            box-shadow: none;
                        }
                        &::after {
                            content: "";
                            position: relative;
                            right: 0px;
                            bottom: 4px;
                            border: none;
                            width: 10px;
                            height: 30px;
                            background-image: url("./../../../assets//icons/ArrowDown.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 10px;
                        }
                    }
                    & > ul {
                        right: 0;
                        bottom: 32px;
                        min-width: 50px;
                        li {
                            a {
                                text-decoration: none;
                                font-weight: 400;
                                font-size: 14px;
                                color: #252830;
                            }
                        }
                    }
                }
            }
        }
    }
}