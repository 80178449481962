@import "/src/styles/core";

.Container {
    padding: 0.75rem 28px 0.75rem 12px;
    border-bottom: 1px solid var(--outline-scale-100);
    // margin: 1.5rem 0;
    display: grid;
    grid-template-areas:
        "indicator icon id actions"
        "content content content content";
    grid-template-columns: 20px 30px auto 125px;
    align-items: center;
    height: 60px;
    // font-size: 0.875rem !important;

    &.Undread {
        border-bottom: 1px solid #E0E0E0 !important;
        background: rgba(0, 68, 187, 0.03) !important;
        .Content {
            font-weight: 600 !important;
        }
        .Actions {
            .arrive_date {
                font-weight: 600;
            }
        }
    }

    .Indicator {
        grid-area: indicator;
        width: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .Bullet {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--accent);
        }
    }

    .Content {
        grid-area: content;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #252830;
        font-weight: 400;
        @media screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }

    .Icon {
        grid-area: icon;
        width: 30px;
        text-align: center;
    }

    .Id {
        grid-area: id;
        color: var(--accent);
        text-decoration: underline;
        padding: 0 0.5rem;
        cursor: pointer;
    }

    .Actions {
        grid-area: actions;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 0.35rem;

        .arrive_date {
            font-weight: 400;
            font-size: 12px;
            color: #0044BB;
        }

        button {
            border: 1px solid var(--accent);
            outline: none;
            background-color: transparent;
            border-radius: 2px;
            color: var(--accent);
            padding: 0.35rem;

            transition: 250ms;
            transition-property: background-color, color;

            &:hover {
                background-color: var(--accent);
                color: white;
            }
        }
    }
}

.PopupContainer {
    width: 226px;

    .Info {
        font-size: 16px;
        color: var(--primary-scale-90);
        line-height: 22px;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--outline-scale-100);
    }

    .Actions {
        display: flex;
    }
}

.Actions {
    button {
        .Small {
            display: block;
        }

        .Large {
            display: none;
        }
    }
}

@include media-breakpoint-up(md) {
    .Container {
        grid-template-areas: "indicator icon id content actions";
        grid-template-columns: 20px 30px max-content auto 200px;
    }
}

@include media-breakpoint-up(lg) {
    .Actions {
        button {
            .Small {
                display: none;
            }

            .Large {
                display: block;
            }
        }
    }
}
