.company_info_wrapper {
    background: #FFFFFF;
    padding: 16px 25px;
    display: grid;
    grid-template-columns: 170px auto;
    padding-bottom: 30px;
    @media screen and (max-width: 500px) {
        grid-template-columns: 100%;
        row-gap: 20px;
    }
    .image_wrapper {
        width: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        img {
            max-width: 90px;
            max-height: 90px;
        }
    }
    .info_wrapper {
        label {
            padding: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #808080;
        }
        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #404040;
        }
        .website, .email, .address, .phone {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #606060;
        }
    }
}