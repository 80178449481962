.Container {
  background-color: var(--white);
  .page_title_wrapper {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 26px 8px 0;
    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    .ad_details_wrapper {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 25px;
      align-items: center;
      .AdFormTitleRowRightDate {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #808080;
      }
      .separator {
        border-left: 1px solid #dfdfdf;
        height: 15px;
      }
      .AdFormTitleRowRightInvoiceNo {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #808080;
        span {
          color: #0044bb;
          font-weight: 700;
        }
      }
    }
  }
  .Separator {
    height: 1px;
    background-color: var(--outline-scale-100);
  }
}
