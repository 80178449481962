.Input {
    position: relative;

    &:not(.NoMargins) {
        margin-bottom: 1rem;
    }

    label {
        color: var(--primary-scale-90);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        font-size: 14px;
        padding-bottom: 0.5rem;

        .RequiredIndicator {
            color: var(--accent);
            font-weight: bold;
        }
    }

    &.Switchable {
        input {
            border: none;
            border-bottom: 1px solid var(--primary-scale-90);
            // border-radius: 0;
        }
    }

    .NumberArrows {
        position: absolute;
        top: 0.5rem;
        right: 10px;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        gap: 2px;
        height: 30px;

        img {
            cursor: pointer;
        }
    }
}

.ErrorInput {
    input {
        border: 1px solid var(--error) !important;
        background-color: #ff000017 !important;
    }

    .ErrorText {
        font-size: 0.75rem;
        color: var(--error) !important;
    }
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.FloatInputContainer {
    height: 45px;
}

.FloatInput {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 35px;
    right: 0;

    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    width: 24px !important;
    cursor: pointer;
    color: var(--accent);
    margin-right: 0.5rem;

    border: 0;
    background-color: transparent;
    // background-color: var(--white);
    // box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.05);
    // padding: 0.3rem;

    // .InputGroupText {
    //     width: 35px;
    //     border: 0;
    //     background-color: transparent;

    //     &.ToggleIcon {
    //         width: 24px !important;
    //         cursor: pointer;
    //         color: var(--accent);
    //         margin-right: 0.5rem;
    //     }
    // }

    // .FormControl {
    //     border: 0;
    //     background-color: transparent;

    //     &:focus {
    //         box-shadow: none;
    //     }
    // }
}
