.Loader {
    position: absolute;
    z-index: 9999999;

    &.Small {
        &::after {
            content: " ";
            display: block;
            width: 25px;
            height: 25px;
            margin: 0;
            border-radius: 50%;
            border: 2px solid #cef;
            border-color: #cef transparent #cef transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
    }

    &.Large {
        &::after {
            content: " ";
            display: block;
            width: 3.5rem;
            height: 3.5rem;
            margin: 0;
            border-radius: 50%;
            border: 0.35rem solid #cef;
            border-color: var(--accent) transparent var(--accent) transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
