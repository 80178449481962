@import "/src/styles/core";

.Button {
    height: 44px;
    width: 44px;
    border-radius: 50%;

    &.Small {
        height: 32px;
        width: 32px;
    }

    &.ButtonAutoHeight {
        height: auto !important;
    }

    .ButtonContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.Borderless {
        border: none !important;
    }

    &.BorderlessTransparent {
        border: none !important;
        background-color: transparent !important;
    }

    &.accent {
        background-color: var(--accent) !important;
        color: var(--primary-scale-70) !important;
    }

    &.light {
        background-color: var(--white);
        border: 2px solid var(--outline-scale-80);

        &:hover {
            &:not(.HoverNone) {
                background-color: var(--accent);
                color: var(--white);
            }
        }
    }

    &.transparent {
        background-color: transparent;
        border: 2px solid var(--outline-scale-80);

        &:hover {
            &:not(.HoverNone) {
                background-color: var(--accent);
                color: var(--white);
            }
        }
    }

    &.other-gray-accent {
        background-color: var(--outline-scale-90);
        color: var(--accent);
        border: 2px solid var(--accent);
    }

    &.light-accent {
        background-color: var(--white);
        color: var(--accent);
        border: 2px solid var(--accent);
    }

    &.dark-gray {
        background-color: var(--outline-scale-80);
        color: var(--primary-scale-80);
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }

    .IconLabel {
        padding: 0 0.5rem;
    }
}

.Link {
    height: 44px;

    &.accent {
        background-color: transparent !important;
        color: var(--accent) !important;
        font-weight: bold;
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }
}

.AsNormalLink {
    font-weight: normal !important;
    text-decoration: underline !important;
    font-size: 14px !important;
}

.Label {
    color: var(--primary-scale-90);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 14px;
}

.InnerLabel {
    display: none;
}

@include media-breakpoint-up(lg) {
    .InnerLabel {
        display: inline-block;
    }
}
