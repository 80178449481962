.LoginLayout {
    height: auto;
    position: relative;

    .Overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.64) 0%,
            var(--accent) 100%
        );
        z-index: 2;
    }

    .BackgroundImage {
        // width: 90%;
        // height: 105%;
        // object-fit: cover;
        position: absolute;
    }
}
