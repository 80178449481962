.modal_wrapper {
    & > div {
        & > div {
            border-radius: 0px;
        }
    }
    .custom_header {
        background: #F2F2F2;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            color: #323232;
        }
    }
    .custom_body {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #323232;
        padding: 23px 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .custom_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px;
        button {
            min-width: 150px;
            height: 44px;
            border: none;
            border-radius: 6px;
            &.cancel_btn {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #0044BB;
                background: transparent;
            }
            &.confirm_btn {
                background: #FF0000;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #FBFBFB
            }
        }
    }
}