.Container {
    padding-bottom: 1rem;
	position: relative;

    .Send {
        text-align: right;
        margin-top: 16px;
    }

	.chat_footer_wrapper {
		display: grid;
		grid-template-columns: auto max-content;
	}

	.UploadedFilesContainer {
		margin-top: 8px;
		border-top: none;
		width: 100%;
		// overflow-x: auto;
		padding-right: 10px;
	}
}
