.overlay_style {
    z-index: 9999;
    .modal_style {
        width: 1141px !important;
        max-width: 100%;
        & > div:first-of-type {
            height: 73px;
            min-height: 73px;
            border: none;
            & > div:first-of-type {
                display: none;
            }
            & > div:last-of-type {
                margin: 0;
                padding: 8px 20px;
                text-align: center;
                h2 {
                    max-width: unset;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    color: #323232;
                }
            }
        }
        & > div:last-of-type {
            padding: 0;
            .body_wrapper {
                padding: 24px 0 0 24px;
                display: grid;
                grid-template-columns: 600px 500px;
                @media screen and (max-width: 1139px) {
                    grid-template-columns: 100%;
                    padding-right: 24px;
                }
                .products_wrapper {
                    .product_wrapper {
                        display: grid;
                        grid-template-columns: 37px auto;
                        .checkbox_wrapper {
                            label {
                                display: block;
                                position: relative;
                                cursor: pointer;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                input {
                                    opacity: 0;
                                    cursor: pointer;
                                    &:checked ~ .checkmark {
                                        background-color: #0044BB;
                                        &:after {
                                            display: block;
                                        }
                                        
                                    }
                                    &:disabled ~ .checkmark {
                                        background-color: #E3E3E3 !important;
                                    }
                                }
                                .checkmark {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 24px;
                                    width: 24px;
                                    border-radius: 2.9px;
                                    border: 1px solid #D9D9D9;
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        display: none;
                                        left: 9px;
                                        top: 5px;
                                        width: 5px;
                                        height: 10px;
                                        border: solid #FFFFFF;
                                        border-width: 0 3px 3px 0;
                                        -webkit-transform: rotate(45deg);
                                        -ms-transform: rotate(45deg);
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                        .product {
                            margin-right: 16px;
                            padding-bottom: 12px;
                            margin-bottom: 12px;
                            border-bottom: 1px solid #DFDFDF;
                            @media screen and (max-width: 1139px) {
                                margin-right: 0;
                            }
                            .title {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #404040;
                            }
                            .category {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                color: #606060;
                                margin-top: 5px;
                            }
                            .description {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                color: #323232;
                                margin-top: 4px;
                            }
                            .quantity_price {
                                margin-top: 15px;
                                display: flex;
                                justify-content: space-between;
                                padding-bottom: 10px;
                                @media screen and (max-width: 630px) {
                                    flex-direction: column;
                                    row-gap: 10px;
                                }
                                .quamtity_wrapper {
                                    display: flex;
                                    flex: 1 1;
                                    align-items: center;
                                    margin-right: 12px;
                                    .label {
                                        margin-right: 8px;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 18px;
                                        color: #404040;
                                        line-height: normal;
                                    }
                                    .value {
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        color: #606060;
                                    }
                                }
                                .price_wrapper {
                                    display: flex;
                                    align-items: center;
                                    @media screen and (max-width: 399px) {
                                        flex-wrap: wrap;
                                        row-gap: 10px;
                                    }
                                    .label {
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 18px;
                                        color: #404040;
                                        line-height: normal;
                                        @media screen and (max-width: 399px) {
                                            flex: 0 1 100%;
                                        }
                                    }
                                    .value {
                                        margin-left: 12px;
                                        @media screen and (max-width: 399px) {
                                            margin-left: 0;
                                        }
                                        .error_text {
                                            position: absolute;
                                        }
                                        &.has_error {
                                            input {
                                                border-color: #FF0000 !important;
                                            }
                                            .error_text {
                                                color: #FF0000;
                                            }
                                        }
                                        input {
                                            background: #FFFFFF;
                                            border: 1px solid #D3D3D3;
                                            border-radius: 6px;
                                            padding: 8px;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 16px;
                                            color: #323232;
                                            max-width: 130px;
                                            &:focus, &:active {
                                                border-color: #D3D3D3 !important;
                                            }
                                            &:focus-visible {
                                                outline: none;
                                            }
                                            &:disabled {
                                                background-color: #E3E3E3;
                                            }
                                            @media screen and (max-width: 399px) {
                                                max-width: unset;
                                            }
                                        }
                                    }
                                    .currency {
                                        margin-left: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        color: #808080;
                                    }
                                }
                            }
                        }
                    }
                }
                .additional_info_wrapper {
                    border-left: 1px solid #DFDFDF;
                    @media screen and (max-width: 1139px) {
                        border: none;
                    }
                    .additional_docs_wrapper {
                        .label {
                            background: #F8F8F8;
                            padding: 8px 24px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            color: #808080;
                        }
                        label {
                            margin-left: 24px;
                            margin-top: 14px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            color: #0044BB;
                            cursor: pointer;
                            &.disabled {
                                color: #E3E3E3;
                            }
                            img {
                                width: 18px;
                                margin-right: 10px;
                            }
                        }
                        .docs_wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            column-gap: 20px;
                            margin: 12px 12px 12px 24px;
                            row-gap: 12px;
                            .doc_wrapper {
                                flex: 0 1 auto;
                                display: flex;
                                align-items: center;
                                padding: 8px;
                                background: #EBF2FF;
                                border: 1px solid #0044BB;
                                border-radius: 6px;
                                .file_icon {
                                    margin-right: 10px;
                                }
                                .file_name {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #0044BB;
                                }
                                .close_icon {
                                    cursor: pointer;
                                    margin-left: 10px;
                                    background: rgba(214, 225, 244, 0.84);
                                    border: 1px solid #0044BB;
                                    border-radius: 5px;
                                    padding: 3px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
                .comment_wrapper {
                    .label {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        color: #808080;
                        background: #F8F8F8;
                        padding: 8px 24px;
                    }
                    label {
                        margin: 12px 0 0 24px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        color: #404040;
                    }
                    textarea {
                        margin: 0 12px 12px 24px;
                        width: calc(100% - 36px);
                        resize: none;
                        background: #FFFFFF;
                        border: 1px solid #DFDFDF;
                        border-radius: 6px;
                        padding: 8px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        color: #323232;
                        &:focus, &:active {
                            border-color: #DFDFDF !important;
                        }
                        &:focus-visible {
                            outline: none;
                        }
                        &:disabled {
                            background-color: #E3E3E3;
                        }
                        @media screen and (max-width: 1139px) {
                            margin-right: 24px;
                            width: calc(100% - 48px);
                        }
                    }
                }
            }
            .footer_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 92px;
                padding-right: 32px;
                margin-bottom: 24px;
                @media screen and (max-width: 399px) {
                    flex-direction: column;
                    justify-content: center;
                    row-gap: 10px;
                    padding: 0 24px;
                    & > div {
                        width: 100% !important;
                        margin: 0 !important;
                    }
                }
                .close_btn {
                    width: 84px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #0044BB;
                    cursor: pointer;
                    text-align: center;
                    padding: 12px 0px;
                    margin-right: 25px;
                }
                .submit_btn {
                    padding: 12px 50px;
                    background: #0044BB;
                    border-radius: 6px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #FBFBFB;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
}