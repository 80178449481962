* {
    font-family: $primaryFont;
}

.dimmed {
    color: var(--primary-scale-80);
}

.semi-dimmed {
    color: var(--primary-scale-90);
}

.accent-text {
    color: var(--accent);
}

.error-text {
    color: var(--error);
}
