.header_container {
    display: grid;
    grid-template-columns: max-content auto max-content;
    align-items: center;
    @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
        row-gap: 10px;
    }
    .title {
        font-size: 18px;
        font-weight: 700;
        color: var(--primary-scale-100);
        margin-right: 18px;
        @media screen and (max-width: 767px) {
            margin-right: 0px;
        }
    }
    .search_bar {
        height: 44px;
        background-color: var(--outline-scale-70);
        border: 1px solid var(--outline-scale-100);
        border-radius: 4px;
        display: flex;
        align-items: center;
        max-width: 452px;
        padding: 12px 18px;

        .icon {
            width: 19px;
            height: 20px;
        }

        input {
            border: none;
            width: 100%;
            height: 19px;
            background-color: transparent;
            padding-left: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #828282;
        }

        @media screen and (max-width: 767px) {
            max-width: calc(100% - 2rem);
            margin: 0 1rem;
        }
    }
    .actions {
        display: flex;
        height: 32px;
        font-style: normal;
        font-size: 14px;
        color: var(--primary-scale-100);
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
        & > * {
            margin-left: 0.5rem;
        }
        .download_list {
            height: 32px;
            outline: none;
            border: 1px solid var(--outline-scale-100);
            background-color: transparent;
            display: flex;
            align-items: center;
            padding: 4px 8px;
            border-radius: 2px;
            @media screen and (max-width: 767px) {
                margin-left: 0;
            }
            span {
                margin-left: 0.5rem;
                font-weight: 400;
                color: #252830;
            }
        }
        .date_container {
            position: relative;
            .date_wrapper {
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 2px;
                padding: 4px 8px;
                font-style: normal;
                font-size: 14px;
                display: flex;
                align-items: center;
                height: 32px;
                &:focus {
                    box-shadow: none;
                }
                &:focus-visible {
                    outline: none;
                }
                img {
                    margin-right: 10px;
                    &:last-of-type {
                        margin-left: 10px;
                    }
                }
                span {
                    font-weight: 400;
                    color: #252830;
                }
            }
        }
    }
}