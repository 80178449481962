.Container {
    flex: 1;
    padding-top: 0.5rem;
}

.Calendar {
    .DaysName {
        display: flex;
        align-items: center;
        text-align: center;

        .DayName {
            flex: 1;
            font-weight: 600;
            font-size: 13px;
            color: var(--primary-scale-90);
        }
    }

    .Days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;

        .Day {
            font-size: 13px;
            font-weight: 400;
            color: var(--primary-scale-90);
            padding: 0.5rem 0;
            cursor: pointer;
            width: 35px;
            height: 35px;

            &.NotCurrentMonth {
                color: var(--outline-scale-100);
            }

            &.Today {
                color: var(--accent);
                font-weight: 600;
                background-color: var(--outline-scale-90);
                border-radius: 50%;
            }

            &.Selected {
                color: var(--white);
                font-weight: 600;
                background-color: var(--accent);
                border-radius: 50%;
            }

            &.IsBeforeToday {
                color: lightgray;
            }
        }
    }
}
