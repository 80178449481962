.Container {
    margin: 0 1rem 1rem 1rem;
    border-radius: 0.5rem;
    height: 140px;
    position: relative;

    canvas {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    #dummy {
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -1000 !important;
    }
}

.Dummy {
    position: absolute;
    width: 1px;
    height: 1px;
    z-index: -1000 !important;
}

.ChartSummary {
    margin: 1rem 1rem 0 1rem;
    background-color: var(--accent);
    border-radius: 0.5rem 0.5rem 0 0;
    color: var(--white);
    padding: 1rem;

    .Info {
        display: flex;
        font-size: 1rem;

        .Label {
            flex: 1;
        }
    }

    .InfoDimmed {
        display: flex;
        font-size: 0.875rem;
        opacity: 0.5;
    }
}
