@import "/src/styles/core";

.Container {
    padding: 27px 23px;
    // grid-area: chat-filter;

    .InputContainer {
        display: flex;
        align-items: center;
        background-color: var(--outline-scale-70);
        border: 1px solid var(--outline-scale-100);
        border-radius: 4px;
        padding: 0.5rem 0.75rem;

        .Input {
            flex: 1;

            input {
                width: 100%;
                outline: none;
                border: none;
                background-color: transparent;
                padding-left: 0.5rem;
            }
        }
    }
}
