.date_picker_custom_style {
    z-index: 999999;
    .react-datepicker {
        box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
        border-radius: 4px;
        border: none;
        padding: 0 15px 20px;
        margin-right: 15px;
        .react-datepicker__triangle {
            display: none;
        }
        & > button {
            display: none;
        }
        .react-datepicker__month-container {
            .react-datepicker__header  {
                background: #FFFFFF;
                border-bottom: none;
                padding-top: 24px;
                padding-bottom: 24px;
                .react-datepicker__current-month {
                    display: none;
                }
                .react-datepicker__day-names {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    color: #323232;
                }
            }
            .react-datepicker__header__dropdown {
                & > div:first-of-type {
                    select {
                        background-color: #F6F6F6;
                        background-image: url("../../../../../assets/icons/ArrowDownBlue.svg");
                        background-position-x: calc(100% - 7px);
                        background-position-y: 14px;
                        background-size: 12px 8px;
                        border-radius: 4px;
                        padding: 8px 18px 8px 8px;
                        color: #0044BB;
                    }
                }
                select {
                    -moz-appearance:none;
                    -webkit-appearance:none;
                    appearance:none;
                    border: none;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #323232;
                    background-image: url("../../../../../assets/icons/ArrowDownGray.svg");
                    background-repeat: no-repeat;
                    background-position-x: 100%;
                    background-position-y: 6px;
                    padding-right: 15px;
                    cursor: pointer;
                    &:focus-visible {
                        outline: none;
                    }
                    option {
                        color: #323232;
                    }
                }
            }
            .react-datepicker__month {
                margin-top: 0;
                margin-bottom: 0;
                .react-datepicker__week {
                    font-style: normal;
                    font-weight: 400;
                    // font-size: 20px;
                    letter-spacing: 0.38px;
                    color: #000000;
                    & > div {
                        margin: 0;
                        width: calc(27.19px + 0.26rem);
                        height: calc(27.19px + 0.166rem);
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        &:last-of-type {
                            &.react-datepicker__day--in-range,
                            &.react-datepicker__day--in-selecting-range {
                                &:not(.react-datepicker__day--selected) {
                                    &::before {
                                        border-radius: 0 26.8px 26.8px 0 !important;
                                    }
                                }
                            }
                        }
                        &:first-of-type {
                            &.react-datepicker__day--in-range,
                            &.react-datepicker__day--in-selecting-range {
                                &:not(.react-datepicker__day--keyboard-selected) {
                                    &::before {
                                        border-radius: 26.8px 0 0 26.8px !important;
                                    }
                                }
                            }
                        }
                        &:hover {
                            border-radius: 66.6px;
                            background: #E6EFFF;
                        }
                        &.react-datepicker__day--keyboard-selected {
                            border-radius: 66.6px !important;
                        }
                        &.react-datepicker__day--in-range,
                        &.react-datepicker__day--in-selecting-range,
                        &.react-datepicker__day--selected {
                            background: transparent;
                            border-radius: unset;
                            color: #000000;
                            position: relative;
                            z-index: 1;
                            &::before {
                                content: "";
                                background: #E6EFFF !important;
                                position: absolute;
                                right: 0;
                                left: 0;
                                top: 15%;
                                bottom: 15%;
                                z-index: -1;
                            }
                        }
                        &.react-datepicker__day--selected {
                            border-radius: 66.6px !important;
                            &::before {
                                border-radius: 66.6px !important;
                                top: 0;
                                bottom: 0;
                            }
                        }
                        &.react-datepicker__day--range-start,
                        &.react-datepicker__day--selecting-range-start {
                            position: relative;
                            color: #FFFFFF;
                            border-radius: 66.6px;
                            z-index: 1;
                            &::before {
                                content: "";
                                background: #E6EFFF !important;
                                border-radius: 66.6px 0 0 66.6px !important;
                                position: absolute;
                                right: 0;
                                left: 0;
                                top: 15%;
                                bottom: 15%;
                                z-index: -1;
                            }
                            &::after {
                                content: "";
                                background: #0044BB;
                                border-radius: 66.6px;
                                position: absolute;
                                right: 0;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                z-index: -1;
                            }
                            &.react-datepicker__day--selecting-range-end {
                                &::before {
                                    display: none;
                                }
                            }
                        }
                        &.react-datepicker__day--selecting-range-end {
                            border-radius: 66.6px;
                        }
                        &.react-datepicker__day--selecting-range-end,
                        &.react-datepicker__day--range-end {
                            background: #0044BB;
                            color: #FFFFFF;
                            border-radius: 66.6px;
                            position: relative;
                            z-index: 1;
                            &::before {
                                content: "";
                                background: #E6EFFF !important;
                                border-radius: 0 66.6px 66.6px 0 !important;
                                position: absolute;
                                right: 0;
                                left: 0;
                                top: 15%;
                                bottom: 15%;
                                z-index: -1;
                            }
                            &::after {
                                content: "";
                                background: #0044BB;
                                border-radius: 66.6px;
                                position: absolute;
                                right: 0;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                z-index: -1;
                            }
                        }
                        &.react-datepicker__day--range-start,
                        &.react-datepicker__day--keyboard-selected {
                            // background: #0044BB;
                            border-radius: 66.6px;
                            font-style: normal;
                            font-weight: 700;
                            // font-size: 18.5231px;
                            letter-spacing: 0.3px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        .react-datepicker__children-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0;
            margin: 0;
            padding-top: 25px;
            div {
                width: 100%;
                text-align: center;
                button {
                    border: none;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 8px 15px;
                    &:first-of-type {
                        margin-right: 8px;
                        background: #F8F7FA;
                        border-radius: 4px;
                        color: #323232;
                    }
                    &:last-of-type {
                        background: #0044BB;
                        border-radius: 4px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}