@import "/src/styles/core";

.UploadButton {
    .icon_preview_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 39px 0 19px 0;
        .icon_preview_image {
            max-width: 100px;
            max-height: 100px;
        }
    }
}

input[type="file"] {
    width: 100%;
    display: none;
}

.ErrorMessage {
    color: var(--error);
    font-size: 0.75rem;
}

.Button {
    height: 44px;
    width: 100%;

    &.AutoWidth {
        width: auto;
    }

    &.accent {
        background-color: var(--accent) !important;
        color: var(--primary-scale-70) !important;
    }

    &.light {
        background-color: var(--white);
        border: 2px solid var(--outline-scale-80);
    }

    &.light-accent {
        background-color: var(--outline-scale-80);
        color: var(--accent);
    }

    &.light-accent-step {
        background-color: var(--outline-scale-80);
        color: var(--accent);
        height: 38px !important;
    }

    &.dark-gray {
        background-color: var(--outline-scale-80);
        color: var(--primary-scale-80);
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }

    .IconLabel {
        padding: 0 0.5rem;
    }
}

.Link {
    height: 44px;

    &.accent {
        background-color: transparent !important;
        color: var(--accent) !important;
        font-weight: bold;
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }
}

.Label {
    color: var(--primary-scale-90);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 14px;
}

.InnerLabel {
    display: none;
}

.FileList {
    display: grid;
    gap: 0.35rem;
    margin-top: 0.5rem;

    &:not(.FileListMultiple) {
        grid-template-columns: 1fr;
    }

    &.FileListMultiple {
        grid-template-columns: 2fr;
    }
}

.RequiredAsterisk {
    color: var(--accent);
}

@include media-breakpoint-up(md) {
    .FileList {
        &:not(.FileListMultiple) {
            grid-template-columns: repeat(1, 1fr);
        }

        &.FileListMultiple {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include media-breakpoint-up(lg) {
    .InnerLabel {
        display: inline-block;
    }

    .FileList {
        &:not(.FileListMultiple) {
            grid-template-columns: repeat(1, 1fr);
        }

        &.FileListMultiple {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
