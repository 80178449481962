.Checkbox {
    // margin-bottom: 0.5rem;

    .Check {
        &.Accent {
            border: 1.5px solid var(--accent);
        }

        &:active,
        &:focus {
            box-shadow: none !important;
        }
    }

    .Label {
        padding-top: 1px;
        // padding-left: 4px;
    }
}

.Large {
    width: 18px;
    height: 18px;
}

.LargeLabel {
    padding-top: 3px;
    padding-left: 4px;
    color: var(--primary-scale-80);
    font-size: 1rem;
}

input[type="checkbox"] {
    &:checked {
        background-color: var(--accent);
    }
}

.LightText {
    color: var(--primary-scale-80) !important;
}
