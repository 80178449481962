.modal_wrapper {
    & > div {
        & > div {
            border-radius: 0px;
            max-height: calc(100vh - 60px);
        }
    }
    .custom_header {
        background: #F2F2F2;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            flex: 1;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            color: #323232;
        }
        & > button {
            padding: 10px;
            background-color: silver;
            border-radius: 50%;
            background-size: 8px;
            width: 28px;
            height: 28px;
            margin-right: 0;
            &:focus {
                box-shadow: none;
            }
            &:focus-visible {
                outline: none;
            }
        }
    }
    .custom_body {
        padding: 16px 18px;
        overflow-y: auto;
        .company_info_wrapper {
            display: grid;
            grid-template-columns: 144px auto 50px;
            column-gap: 12px;
            border-bottom: 1px solid #DFDFDF;
            padding-bottom: 15px;
            .image_wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                .image_container {
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100px;
                        max-height: 100px;
                    }
                }
                .company_name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    color: #323232;
                }
            }
            .company_info {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #606060;
                .company_name {
                    font-weight: 600;
                    color: #404040;
                }
                & > div {
                    &:not(:last-of-type) {
                        margin-bottom: 6px;
                    }
                }
            }
            .message_icon {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                .image_wrapper {
                    border: 1px solid #0044BB;
                    border-radius: 100%;
                    width: 42px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img {
                        width: 18px;
                        height: 16px;
                    }
                }
            }
        }
        .contact_wrapper {
            border-bottom: 1px solid #DFDFDF;
            padding: 8px 0 12px;
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                color: #404040;
            }
            .contact_info_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 6px;
                & > div {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    color: #606060;
                }
            }
        }
        .comment_wrapper {
            padding: 12px 0;
            border-bottom: 1px solid #DFDFDF;
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                color: #404040;
                margin-bottom: 12px;
            }
            textarea {
                resize: none;
                width: 100%;
                background: #F5F5F5;
                border: 1px solid #DFDFDF;
                border-radius: 6px;
                padding: 16px 12px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #323232;
                &:focus-visible {
                    outline: none;
                }
            }
        }
        .additional_docs_wrapper {
            padding: 12px 0;
            border-bottom: 1px solid #DFDFDF;
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                color: #404040;
            }
            .docs_wrapper {
                margin-top: 12px;
                display: flex;
                flex-wrap: wrap;
                column-gap: 19px;
                row-gap: 12px;
                & > div {
                    display: flex;
                    align-items: center;
                    background: #EBF2FF;
                    border: 1px solid #0044BB;
                    border-radius: 6px;
                    padding: 16px 8px;
                    .icon {
                        margin-right: 10px;
                        width: 24px;
                        height: 24px;
                        -webkit-mask-image: url("../../../../../assets/icons/FileText.svg");
                        mask-image: url("../../../../../assets/icons/FileText.svg");
                        -webkit-mask-size: contain;
                        mask-size: contain;
                        background: #0044BB;
                    }
                }
            }
        }
    }
    .custom_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 15px 28px;
        .price_wrapper {
            .label {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #404040;
            }
            .price {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #323232;
            }
        }
        .buttons_wrapper {
            button {
                min-width: 150px;
                height: 44px;
                border: none;
                border-radius: 4px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #FBFBFB;
                padding: 10px;
                .icon {
                    -webkit-mask-size: contain;
                    mask-size: contain;
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    background: #FFFFFF;
                    margin-right: 5px;
                    vertical-align: middle;
                    display: inline-block;
                }
                &.refuse_btn {
                    margin-right: 24px;
                    background: #FC3333;
                    .icon {
                        -webkit-mask-image: url("../../../../../assets/icons/Close.svg");
                        mask-image: url("../../../../../assets/icons/Close.svg");
                        width: 10px;
                        height: 10px;
                    }
                }
                &.accept_btn {
                    background: #41CA00;
                    .icon {
                        -webkit-mask-image: url("../../../../../assets/icons/Checkmark_White.svg");
                        mask-image: url("../../../../../assets/icons/Checkmark_White.svg");
                        width: 16px;
                        height: 10px;
                    }
                }
            }
        }
    }
}