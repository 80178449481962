.Card {
  background-color: var(--white);
  border: 1px solid var(--white);
  // padding: 2rem;
  margin-bottom: 2rem;

  &.Invalid {
    // border: 1px solid var(--error);
  }
}
